import { Container, chakra, useToast } from '@chakra-ui/react';
import Head from 'next/head';
import { LoginForm } from 'src/components/molecules';
import { useRouter } from 'next/router';
import { useAuth } from 'src/utils/auth';
import { useEffect, useRef } from 'react';
import { BLACK, WHITE } from 'src/constants/ui';

const Login = () => {
  const router = useRouter();
  const toast = useToast();
  const { isAuthenticated } = useAuth();
  const toastShownRef = useRef(false);

  useEffect(() => {
    if (isAuthenticated) {
      router.push('/');
    }
  }, [isAuthenticated, router]);

  useEffect(() => {
    if (router.isReady && !toastShownRef.current) {
      if (router.query.success === 'true') {
        toast({
          title: 'Success',
          description: router.query.message as string,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else if (router.query.success === 'false') {
        toast({
          title: 'Error',
          description: router.query.message as string,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      toastShownRef.current = true;
    }
  }, [router.isReady, router.query]);

  const handleSuccessfulLogin = () => {
    router.push('/');
  };

  return (
    <>
      <Head>
        <title>Login</title>
        <meta name="login" content="Login" />
      </Head>
      <chakra.main bg={WHITE} color={BLACK} minH="50vh">
        <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
          <LoginForm handleSuccessfulLogin={handleSuccessfulLogin} />
        </Container>
      </chakra.main>
    </>
  );
};

export default Login;
